export const BUILDER_ROUTE_BASE = '/builder';

export const BUILDER_ROUTE_PATHS = {
    GUILD_BUILDER: '/guild/create',
    GUILD_BUILDER_EDIT: '/guild/:guildSlug/edit',
    MISSION_INTRO: '/guild/:guildSlug/mission/intro',
    MISSION_BUILDER: '/guild/:guildSlug/mission/create',
    MISSION_BUILDER_DESCRIPTION: '/guild/:guildSlug/mission/:missionSlug/description',
    MISSION_BUILDER_EDIT: '/guild/:guildSlug/mission/:missionSlug/edit',
    MISSION_BUILDER_REWARDS: '/guild/:guildSlug/mission/:missionSlug/rewards',
    MISSION_BUILDER_MISSION_LEARNING_PATHS: '/guild/:guildSlug/mission/:missionSlug/learning-paths/:learningPathSlug',
    MISSION_BUILDER_GUILD_LEARNING_PATHS: '/guild/:guildSlug/learning-paths/:learningPathSlug',
    ROUND_BUILDER: '/guild/:guildSlug/mission/:missionSlug',
    NOT_FOUND: '/:pathMatch(.*)*',
};

// Used for static routes outside of builder router scope
export const FULL_BUILDER_ROUTE_PATHS = Object.fromEntries(
    Object
        .entries(BUILDER_ROUTE_PATHS)
        .map(([key, value]) => ([key, `${BUILDER_ROUTE_BASE}${value}`])),
);

export const BUILDER_ROUTE_NAMES = {
    GUILD_BUILDER: 'guild-builder',
    GUILD_BUILDER_EDIT: 'guild-builder-edit',
    MISSION_BUILDER: 'mission-builder',
    MISSION_BUILDER_DESCRIPTION: 'mission-builder-description',
    MISSION_BUILDER_EDIT: 'mission-builder-edit',
    MISSION_BUILDER_REWARDS: 'mission-builder-rewards',
    MISSION_BUILDER_MISSION_LEARNING_PATHS: 'mission-builder-mission-learning-paths',
    MISSION_BUILDER_GUILD_LEARNING_PATHS: 'mission-builder-guild-learning-paths',
    MISSION_INTRO: 'mission-intro',
    NOT_FOUND: 'not-found',
    ROUND_BUILDER: 'round-builder',
};
